@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "PoppinsBold";
    src: local("PoppinsBold"),
        url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: Bold;
}
@font-face {
    font-family: "PoppinsRegular";
    src: local("PoppinsRegular"),
        url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: Regular;
}
